.slider {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  min-width: 20px;
  max-width: 120px;
  cursor: grab;
  transition: opacity 800ms ease-in-out 200ms;
  z-index: 9999;
}
.slider:hover {
  opacity: 1;
  transition-delay: 0ms;
}
.slider .section-f-height {
  min-height: auto !important;
}
.slider .slider__size {
  position: relative;
  z-index: 3;
}
.slider .slider__controller {
  width: 100%;
  padding-top: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  background-color: rgba(255, 255, 255, 0.25);
}
.slider .slider__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform-origin: 0 0;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: rgba(2, 12, 27, 0.7);
}

body::-webkit-scrollbar-thumb {
  background-color: #495670;
  border: 3px solid rgba(2, 12, 27, 0.7);
  border-radius: 10px;
}
